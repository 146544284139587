.tabRoot
  overflow: auto
  padding: 0 10px

.tabBar
  margin-top: 30px
  margin-bottom: 20px
  width: 600px
  display: flex
  align-items: self-end

.tabLink
  margin-right: 8px
  font-weight: 500
  font-size: 18px
  padding: 8px 16px
  border-radius: 8px
  cursor: pointer

.active
  background-color: #eee