.root
  position: fixed
  right: 24px
  bottom: 62px
  z-index: $animated-page-banner-z-index

.appear, .enter
  transform: translateX(100%)

.appearActive, .enterActive
  transform: translateY(0)
  transition: transform linear 1000ms

.exit
  transform: translateY(0)

.exitActive
  transform: translateX(100%)
  transition: transform linear 1000ms

.container
  position: relative
  width: 100%
  max-width: 435px
  padding: 24px
  border-radius: 16px
  box-shadow: 0px 3px 7px rgba(65, 65, 65, 0.12)
  box-sizing: border-box

.close.close
  position: absolute
  top: 16px
  right: 16px
  width: 24px
  height: 24px

  svg
    fill: $black

  &:hover
    svg
      fill: $black

@media ($min-desktop)
  .root
    bottom: 24px
