.container
  background-color: #B6A9F7

.badge
  display: inline-flex
  align-items: center
  margin-top: 24px
  padding: 4px 16px 4px 4px
  border-radius: 48px
  background-color: $white

.icon
  margin-right: 8px
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25)

.name
  color: $purple
  font-weight: 500
  font-size: 24px
  line-height: 31px
  text-transform: uppercase

.title
  margin-top: 16px
  font-weight: 500
  font-size: 24px
  line-height: 31px

.description
  margin-top: 8px
  font-size: 14px
  line-height: 16px

.descriptionTypeBold
  font-weight: 500

.button.button
  margin-top: 22px
  width: fit-content

  &:hover
    color: $black
    border-color: $black
