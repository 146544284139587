.list
  display: inline-flex
  align-items: center
  margin: 0 -2px
  justify-content: center

.item
  padding: 0 2px
  font-size: 0

.icon
  cursor: pointer
  transition: fill .2s ease-in-out 0s

.iconTypeReadonly
  cursor: inherit
