.root
  display: inline-block
  vertical-align: middle
  border-radius: 18px
  font-size: 12px
  text-transform: uppercase

.inner
  display: block
  padding: 4px 8px

.themeWhite
  background: white
  color: $black

.themeRed
  background: $red
  color: white

.colorBlack
  color: $black

.colorRed
  color: $red

.colorOrange
  color: $orange
