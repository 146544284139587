.root
  display: flex
  flex-direction: column
  border-radius: 8px
  border: 1px solid #eee
  padding: 16px
  box-sizing: border-box
  gap: 16px

.product
  display: flex
  align-items: center
  gap: 16px

.image
  width: 40px
  border-radius: 8px
  flex-shrink: 0

.questionBlock
  flex-grow: 1

.title.title
  font-size: 14px

.productName
  margin-top: 4px


.actionButtons
  display: flex
  gap: 8px

.close.close
  width: 24px
  height: 24px
  flex-shrink: 0
  align-self: baseline


@media ($min-desktop)

  .root:not(.compact)
    padding: 16px 24px
    flex-direction: row
    gap: 24px
    align-items: center
    justify-content: space-between

    .product
      gap: 24px
      flex-grow: 1

    .image
      width: 50px

    .title.title
      font-size: 16px

    .actionButtons
      width: 200px
      flex-shrink: 0

    .close.close
      align-self: auto
