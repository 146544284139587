.product
  flex-shrink: 0
  width: 160px !important
  margin-right: 10px

  &:focus
    outline: none

.list
  display: flex
  width: 100%
  overflow: auto
  padding-bottom: 10px


@media ($min-desktop)
  .product
    width: 190px !important
    margin-top: 32px
    margin-right: 23px
