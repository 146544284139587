.userAvatar
  border-radius: 50%

.root
  background-color: $white
  display: flex
  flex-direction: column
  align-items: center
  padding: 24px 24px 80px 24px

.banner
  right: 0
  bottom: 0

.title
  line-height: 30px
  margin-top: 16px
  text-align: center

.products
  display: flex
  align-items: center
  margin-bottom: 24px
  font-weight: bold
  width: 100%

.productImage
  border-radius: 8px
  margin-right: 16px

.description
  text-align: center
  margin: 16px 0 24px 0

.firstImage,
.secondImage
  border-radius: 8px
  height: auto
  position: absolute

.firstImage
  left: 0
  top: 0
  z-index: 1

.secondImage
  right: 0
  bottom: 0
  border: 1px solid $white
  z-index: 2

.imageSide
  flex-shrink: 0
  margin-right: 16px
  width: 40px
  height: 56px
  border-radius: 8px
  overflow: hidden
  position: relative

@media ($min-desktop)
  .banner
    right: 32px
    bottom: 32px

  .root
    padding: 16px 24px 45px 24px

  .description
    text-align: center
    margin: 8px 0 16px 0

  .products
    margin-bottom: 16px
