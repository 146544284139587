.modal.modal
  width: 100%
  min-width: 340px
  max-width: 480px
  box-sizing: border-box

.message
  font-size: 14px
  line-height: 16px
  color: $black

.buttons
  display: flex
  flex-direction: column
  gap: 16px
  margin-top: 32px

@media ($min-desktop)
  .modal.modal
    min-width: 480px

  .buttons
    flex-direction: row
    align-items: center
    justify-content: flex-end
    
  .button.button
    width: fit-content
