.root
  position: fixed
  bottom: 48px
  left: 0
  right: 0
  display: flex
  align-items: center
  justify-content: space-between
  height: 56px
  padding: 0 16px

.button.button
  width: auto
  border: 1px solid
  height: 32px
  margin-left: 20px
  transition: border-color .3s, color .3s

.lightOrange
  background-color: #fef5e1

  .button
    color: $black
    border-color: $black

    &:hover
      border-color: #444
      color: #444

.red
  background-color: $red
  color: $white

  .button
    color: $white
    border-color: $white

    &:hover
      border-color: #ddd
      color: #ddd


@media ($min-desktop)

  .root
    justify-content: center
    bottom: 0