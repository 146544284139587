.root
  display: flex
  flex-direction: column
  border: 1px solid $light-grey
  padding: 16px
  border-radius: 8px
  position: relative

.content
  display: flex

.imageSide
  flex-shrink: 0
  margin-right: 16px
  width: 56px
  height: 80px
  border-radius: 8px
  overflow: hidden
  position: relative

.productImagePlaceholder
  width: 100%
  height: 100%
  background-color: $warm-grey

.productImage
  width: 100%
  height: auto

.firstOrderImage,
.secondOrderImage
  border-radius: 8px
  width: 40px
  height: auto
  position: absolute

.firstOrderImage
  left: 0
  top: 0
  z-index: 1

.secondOrderImage
  right: 0
  bottom: 0
  border: 1px solid $white
  z-index: 2

.contentText
  display: flex
  flex-direction: column
  justify-content: center

.unpaidOrderText
  font-weight: 500
  font-size: 16px
  margin-bottom: 4px

.bold
  font-weight: 500

.close.close
  position: absolute
  right: 16px
  top: 16px
  width: 24px
  height: 24px

.buttons
  display: flex
  gap: 16px
  margin-top: 16px

@media ($min-desktop)

  .root
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 16px 72px 16px 24px

  .close.close
    transform: translateY(-50%)
    right: 24px
    top: 50%

  .buttons
    width: 320px
    margin-top: 0

