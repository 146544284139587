.modal.modal
  width: 100%
  min-width: 340px
  max-width: 480px
  box-sizing: border-box

.description
  margin: 16px 0
  font-size: 14px
  line-height: 16px

.timer
  margin-top: 16px
  font-size: 12px
  color: #8E8E8E
  text-align: center

.submit.submit
  margin-top: 32px

.resend.resend
  margin-top: 8px
  color: $green
