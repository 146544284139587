.rootTypeSimple
  right: 0
  bottom: 48px
  width: 100%

.banner
  background: #B6A9F7

.bannerTypeSimple
  max-width: none
  border-radius: 0
  box-shadow: none
  background-color: $light-grey

.simpleBannerContent
  display: flex
  align-items: center

.simpleBannerImage
  flex-shrink: 0
  display: block
  margin-right: 16px

.simpleBannerTitle
  font-weight: 500
  font-size: 14px
  line-height: 18px

.simpleBannerDescription
  margin-top: 2px
  font-size: 12px
  line-height: 16px

.simpleBannerButton
  margin-top: 16px

.content
  display: flex
  align-items: flex-end
  gap: 22px

.title
  font-size: 22px
  line-height: 24px

.caption
  margin-top: 16px
  font-size: 16px
  line-height: 20px

.download
  display: flex
  align-items: center
  gap: 16px
  margin-top: 36px

.imageColumn
  position: relative
  bottom: -24px
  font-size: 0
