/* stylelint-disable value-no-vendor-prefix */

.root
  overflow: hidden
  display: flex
  flex-direction: column
  height: 100%

.highlighted
  background-color: #E2F8EE
  padding: 6px
  border-radius: 32px

.ratioContainer
  position: relative
  display: flex
  justify-content: center
  border-radius: 16px
  align-items: center
  background-color: $warm-grey

  &:before
    display: inline-block
    vertical-align: middle
    padding: 138.7% 0 0
    content: ''

  &:after
    position: absolute
    right: 0
    bottom: 0
    left: 0
    height: 50px
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))
    transition: opacity 200ms ease
    content: ''
    z-index: 2

.ratioContainer > *
  position: absolute
  width: 100%
  height: 100%

.ratioContainerTypeAdult > *
  width: auto
  height: auto

.image
  z-index: 1
  opacity: 0
  transition: opacity .3s

.imageLoaded
  opacity: 1

.imagePreloader
  width: 60px
  height: 60px
  fill: $grey-hover

.cardFooterInfo
  display: flex
  flex-direction: column
  flex: auto

.imageLoaded + .imagePreloader
  display: none

.badges
  display: flex
  position: absolute
  top: 16px
  left: 16px
  z-index: 2
  flex-wrap: wrap

.badge
  margin: 2px 0
  margin-left: 5px

.brand
  position: absolute
  bottom: 16px
  left: 16px
  font-size: 18px
  font-weight: 500
  color: $white
  z-index: 2

.badge,
.brand
  opacity: 1
  transition: opacity .2s ease
  min-width: 32px
  min-height: 24px
  display: flex
  align-items: center
  justify-content: center

.badgeIcon
  width: 16px
  height: 16px

.uaBadgeIcon
  width: 16px
  height: 16px

.checkboxOuter
  position: absolute
  top: 0
  right: 0
  padding: 12px
  z-index: 3

.checkbox
  border: 2px solid $white
  border-radius: 1px

.multipleAction
  display: flex
  flex-direction: column
  justify-content: center
  padding: 20px
  position: absolute
  border-radius: 16px
  top: 0
  left: 0
  right: 0
  bottom: 0
  opacity: 0
  z-index: 2
  background: rgba(0, 0, 0, 0.5)
  color: white
  transition: opacity .2s ease
  font-size: 18px
  font-weight: 500
  text-align: center

.aboutTitle.aboutTitle
  font-size: 14px
  text-decoration: none
  overflow: hidden
  color: $black
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  text-underline-offset: auto
  margin: 8px 0

  &:hover
    color: $black
    text-decoration: underline

.discountArea
  display: flex

.oldPrice
  display: block
  vertical-align: middle
  color: rgba(8, 8, 8, .5)
  font-size: 13px
  text-decoration: line-through

.discount
  box-sizing: border-box
  font-size: 12px
  color: #F993B9
  border: 1px solid #F993B9
  margin-left: 8px
  padding: 0 3px
  height: 20px
  min-width: 45px
  display: flex
  border-radius: 4px
  justify-content: center
  align-items: center

.saleLabel
  width: fit-content
  margin-top: 8px

.sale
  color: $red

.moreSize
  font-weight: 400
  padding: 3px 4px
  font-size: 14px

.link
  display: inline-block
  position: relative
  text-decoration: none
  overflow: hidden
  border-radius: 16px
  width: 100%

.info
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  margin-top: 10px
  flex: auto

.price
  font-size: 18px
  font-weight: 500

.sizes
  display: block
  font-size: 13px
  font-weight: 500

.price
  display: inline-block
  vertical-align: middle

.footerAction
  margin-top: 13px
  margin-bottom: 10px

.disabled
  .footerAction,
  .image
    opacity: .55

.processingMultipleAction

  &.disabled
    .image
      opacity: 1

  .multipleAction
    opacity: 1

.priceBlock
  display: flex
  width: 100%
  justify-content: space-between
  margin-bottom: 8px
  align-items: flex-start

.rating
  display: flex
  flex-wrap: wrap
  align-items: center
  margin-top: 4px

.swap
  font-size: 16px
  font-weight: 500
  line-height: 24px
  color: #F993B9

@media ($min-tablet)

  .multipleAction,
  .ratioContainer,
  .link
    border-radius: 32px
