.title.title
  font-size: 16px
  font-weight: 500
  line-height: 24px

.list
  margin-top: 16px

.item
  & + &
    margin-top: 8px

.itemStateHidden
  display: none

.button.button
  display: block
  width: fit-content
  height: auto
  margin-top: 16px
  font-size: 14px
  font-weight: 500
  text-decoration: underline
  text-decoration-style: dashed
