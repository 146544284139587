.root
  padding: 10px

.imagePreloader
  height: 235px
  width: 100%
  background-color: $warm-grey
  border-radius: 16px
  margin-bottom: 21px

.actionButtonPreloader
  width: 100%
  height: 40px
  background-color: $warm-grey


@media ($min-desktop)

  .imagePreloader
    border-radius: 32px
    height: 290px
