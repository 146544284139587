/* stylelint-disable declaration-no-important */

.root
  margin: auto
  padding: 18px 0

// New slider
.root
  [class="slick-track"]
    display: flex !important

.slideItem
  width: 100vw !important
  padding: 0 8px !important

.slideItemSingle
  margin: 0 auto

.slideImage
  width: 96%
  height: auto
  border-radius: 16px

.dots
  display: flex !important
  position: absolute
  bottom: 16px
  left: 50%
  transform: translateX(-50%)

  & > li
    border-radius: 100%
    border: 1px solid $white
    width: 6px
    height: 6px
    box-sizing: border-box
    background-color: transparent
    color: transparent

    &:not(:last-child)
      margin-right: 6px

    & > button
      display: none

  & > li[class="slick-active"]
    background-color: $white

.arrowRight.arrowRight
  left: calc(50% + 558px)

  .icon
    margin-left: 5px

.arrowLeft.arrowLeft
  left: calc(50% - 558px)

.arrowLeft, .arrowRight
  width: 64px
  height: 64px

.slideLink.slideLink
  display: flex
  justify-content: center

@media ($min-tablet)

  .root
    padding: 0 0 16px 0

  .slideItem
    max-width: 980px
    max-height: 300px
    overflow: hidden

  .slideImage
    width: 100%
    border-radius: 32px
