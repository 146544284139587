.root
  padding: 10px

  .button.button
    width: fit-content
    margin: 0 auto
    color: $black
    border-color: $black

    &:after
      position: relative
      left: 0
      margin-left: 5px
      content: '→'
      font-family: "Minion Pro"
      transition: all .15s linear

    &:hover:after
      left: 5px
      color: $bordered-button-active-border-color