.banner
  display: flex
  align-items: flex-end
  gap: 22px
  background: #B6A9F7

.title
  font-size: 22px
  line-height: 24px

.caption
  margin-top: 16px
  font-size: 16px
  line-height: 20px

.download
  display: flex
  align-items: center
  gap: 16px
  margin-top: 36px

.qrCode
  flex-shrink: 0

.storeList
  flex-shrink: 0

.imageColumn
  position: relative
  font-size: 0

.imageColumnPositionBottom
  bottom: -24px
