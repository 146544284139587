.root
  width: 38px
  height: 38px
  display: none
  justify-content: center
  align-items: center
  border-radius: 50%
  position: absolute
  top: 50%
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
  z-index: 2
  text-align: center
  line-height: 60px
  background: white
  cursor: pointer
  transform: translate(-50%, -50%)

  &:hover
    fill: $black

  &:focus
    outline: none

  &.root:before
    content: none


.arrowRight
  left: 100%

.arrowLeft
  left: 0

  .icon
    transform: rotate(180deg)

.icon
  width: 24px
  height: 24px
  fill: $grey-color
  transition: fill .15s

@media ($min-desktop)

  .root
    display: flex