.modal.modal
  width: 100%
  max-width: 480px
  box-sizing: border-box

.title.title
  font-size: 24px
  font-weight: 500
  line-height: 32px

.list
  margin-top: 16px

.item
  display: flex
  align-items: center
  gap: 8px
  font-size: 14px
  font-weight: 500

  & + &
    margin-top: 8px

.description
  margin-top: 16px

.button.button
  width: fit-content
  margin: 32px 0 0 auto
